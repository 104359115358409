import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EncriptaService {

  constructor() { }

  encripta64(m)
  {
    let e1 = btoa(m);
    let e2 = btoa(e1);
    let e3 = btoa(e2);
    let e4 = btoa(e3);
    return e4;
  }
  
  desencripta64(m)
  {
    let e1 = atob(m);
    let e2 = atob(e1);
    let e3 = atob(e2);
    let e4 = atob(e3);
    return e4;
  }
    
  decodificaRespuesta(res)
  {
        let datos2 = this.desencripta64(res['mensaje']);
        let datos3 = JSON.parse(datos2);
        return datos3;
  }

  codificaMensaje(mensaje)
  {
    let xmensaje = this.encripta64(JSON.stringify(mensaje));
    let xpp = {mensaje: xmensaje};
    return xpp; 
  }
    
  encode_utf8( s )
  {
    return unescape( encodeURIComponent( s ) );
  }
  
  decode_utf8( s )
  {
    return decodeURIComponent( escape( s ) );
  }
}
