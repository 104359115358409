<div *ngIf="!cargando" class="producto">
    <div class="linea">
        <div class="nombre">{{ imprimeNombre(product.name) }}</div>
        <div class="precio">${{ product.newPrice | separadormiles }} </div>
        <div *ngIf="!viewOnly" class="boton"><button mat-mini-fab color="warn" (click)="remove(product)">
            <mat-icon>
            delete_forever
          </mat-icon></button>  
        </div>
    </div>
    <div *ngIf="!viewOnly" class="adicionales">
        <span>*Valor Base:  ${{product.preciolista | separadormiles }}</span>
    </div>
    <div class="adicionales" *ngFor="let opcion of product.opciones">
        <span>{{ opcion.descdetalle }}</span>
        <div class="adicionales" *ngFor="let mod of opcion.seleccionactual">
            <span>>>*{{ mod.NOMBREMODI }} <span *ngIf="mod.PRECIO > 0"> por ${{mod.PRECIO | separadormiles}}</span></span>
        </div>
    </div>
    <div class="adicionales" *ngFor="let mod of product.seleccionactual">
        <span>*{{ mod.NOMBREMODI }} <span *ngIf="mod.PRECIO > 0"> por ${{mod.PRECIO | separadormiles}}</span></span>
    </div>
</div>