import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-dialogo2',
  templateUrl: './dialogo2.component.html',
  styleUrls: ['./dialogo2.component.scss']
})
export class Dialogo2Component implements OnInit {


  combo=[];
  titulo:string;
  contenido:string;
  cerrarlbl:string;
  rechazarlbl:string;
  aceptarlbl:string;
  okbtn:boolean;
  nokbtn:boolean;
  closebtn:boolean;
  despliegacombo:boolean;
  productoid:string;
  productosimple:any;
  dialogopropina:boolean;
  dialogoretiro:boolean;
  dialogoborrarcarro:boolean;
  dialogodescuento:boolean;
  montopropina=0;
  totalventa=0;
  idporcentajepropina=0;
  valorpropina:any;
  comentario:string='';
  recibiocambios:boolean;
  productoparaverificar:any;
  totalopciones:number;
  rut:string='';
  email:string='';
  constructor(public dialogRef: MatDialogRef<Dialogo2Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,private appService:AppService) 
  { 

    this.recibiocambios = false;
    if (data['combo']) this.combo = data['combo'];

    this.okbtn = true;
    this.nokbtn = true;
    this.closebtn = false;
    this.despliegacombo = true;
    this.cerrarlbl = 'Salir';
    this.aceptarlbl = 'Agregar';
    this.rechazarlbl = 'Rechazar';
    this.productoid = '';
    this.dialogopropina = false;
    this.dialogoborrarcarro = false;
    this.dialogodescuento = false;
    if (data['titulo'])  this.titulo = data['titulo'];
    if (data['contenido']) this.contenido = data['contenido'];
    if (data['okboton']) this.okbtn = true;
    if (data['nokboton']) this.nokbtn = true;
    if (data['closeboton']) this.closebtn = true;
    if (data['aceptarlbl']) this.aceptarlbl = data['aceptarlbl'];
    if (data['rechazarlbl']) this.rechazarlbl = data['rechazarlbl'];
    if (data['cerrarlbl']) this.cerrarlbl = data['cerrarlbl'];
    if (data.hasOwnProperty('despliegacombo')) this.despliegacombo = data['despliegacombo'];
    if (data['productoid']) this.productoid = data['productoid'];
    if (data['productosimple']) this.productosimple = data['productosimple'];
    if (data['rutdcto']) this.rut = this.stringARut(data['rutdcto']);
    if (data['emaildcto']) this.email = data['emaildcto'];
    if (data.hasOwnProperty('cambiopropina')) {
      this.dialogopropina = true;
      this.montopropina = data['montopropina'];
      this.idporcentajepropina = data['idporcentajepropina'];
      this.totalventa = data['totalventa'];
      this.valorpropina = {id:this.idporcentajepropina,monto:this.montopropina};
    }
    if (data.hasOwnProperty('dialogoretiro')) {
      this.dialogoretiro = true;
      this.comentario = data['nombreretira'];
    }
    if (data.hasOwnProperty('dialogoborrarcarro')) {
      this.dialogoborrarcarro = true;
    }
    if (data.hasOwnProperty('dialogodescuento')) {
      this.dialogodescuento = true;
    }

    this.totalopciones = 1;
    
  }

  ngOnInit() {
//    console.log(this.productosimple);
  }

   cambiaTotalOpciones(total)
   {
      this.totalopciones = total;
   }

   getSeleccion(combo)
   {
    this.combo = combo;
    this.recibiocambios = true;
   }


   verificaModificador(producto)
   {
      let respuesta = false;
      if (Array.isArray(producto.seleccionactual))
        if (producto.seleccionactual.length == this.totalopciones) respuesta = true;
      return respuesta;
   }


   getMaximoModificador(opcion)
   {
    let out = 0;
    if (opcion.NUMERO == 1) out = opcion.MAXMODIFI;
    if (opcion.NUMERO == 2) out = opcion.MAXMODIFI2;
    if (opcion.NUMERO == 3) out = opcion.MAXMODIFI3;
    if (opcion.NUMERO == 4) out = opcion.MAXMODIFI4;
    if (out == 0) out = 1;
    return out;
   }

   verificaOpcionCombo(opcioncombo)
   {
//    console.log(opcioncombo.seleccionactual);
//    console.log(opcioncombo.nromodificadores);

      if ((opcioncombo.nromodificadores == -1) && (opcioncombo.seleccionado == true)) return true;
      if (Array.isArray(opcioncombo.seleccionactual))
      {
        let modificadores = [];
        opcioncombo.seleccionactual.forEach(x => {
          if (modificadores.hasOwnProperty(x.NUMERO)) 
          {
            modificadores[x.NUMERO].total = modificadores[x.NUMERO].total + 1;
          } 
            else modificadores[x.NUMERO] = { total:1, maximo: this.getMaximoModificador(x) };
        }); 

//        console.log(modificadores);
        let valido = true;
        modificadores.forEach(x => {
          if (x.hasOwnProperty('total')) 
          if (x.total != x.maximo) valido = false && valido;
          else valido = true && valido;
        });
        return valido;
        //if (opcioncombo.seleccionactual.length == opcioncombo.nromodificadores) return true;
      }
      return false;
   }


   verificaCombo()
   {
        let listaopciones = new Map();
        this.combo.forEach(x => {
          const detplanmenu = x.DETPLAMEN;
          if (!listaopciones.has(detplanmenu)) {
            listaopciones.set(detplanmenu, []);
          }
          listaopciones.get(detplanmenu).push(x);
        });

        let valido = new Map();
        listaopciones.forEach((value, key) => {
          let opcionactual = value[0].DETPLAMEN;
          valido.set(opcionactual,false);
          value.forEach(z =>{
            if (z.seleccionado == true) 
            {
                valido.set(opcionactual,this.verificaOpcionCombo(z));
            }  
          });
      
      });
        
      let salida = true;
      valido.forEach((value,key) =>{
          let llave = key;
          salida = salida && value;
      });

      return salida;
   }

   close(valor)
   {
     var salida = 0;
     if (valor) salida = valor;   
     this.comentario.trim();
     if (this.dialogoretiro) 
     {
      if ((this.comentario.trim() == '') && (salida == 1)){
        this.appService.mensaje('Debe ingresar un nombre.');
        return;
      }
      let ob = {estado:salida,datos:{nombreretira:this.comentario}};
      this.dialogRef.close(ob);
      return;
     }

     let listoparasalir = true;
     if ((!this.dialogopropina) && (!this.recibiocambios) && (salida == 1) && (!this.despliegacombo)) listoparasalir = false;
     if ((!this.dialogopropina) && (this.recibiocambios) && (salida == 1) && (!this.despliegacombo)) listoparasalir = this.verificaModificador(this.productoparaverificar);
     if ((!this.dialogopropina) && (salida == 1) && (this.despliegacombo)) listoparasalir = this.verificaCombo();

     if (!listoparasalir) {
        this.appService.mensaje('Debe completar la selección antes de continuar.');
        return;
     }            

     if (this.dialogodescuento)
      {
        if (salida == 1)
        {
          if (!this.validaRut(this.rut)) { this.appService.mensaje('Debe ingresar un rut válido.'); return; }
          if (!this.validaEmail(this.email)) { this.appService.mensaje('Debe ingresar un correo válido.'); return; }  
        }
        let ob = {estado:salida,datos:{rut:this.formateaRut(this.rut), email:this.email}};
        this.dialogRef.close(ob);
        return;
      }

     if (this.dialogopropina) this.dialogRef.close({estado:salida,datos:this.valorpropina});
     if (!this.dialogopropina) this.dialogRef.close({estado:salida,datos:this.combo,comentario:this.comentario});



   }
 
   ok()
   {
      this.close(1);
   }
 
   nok()
   {
     this.close(2);
   }
 

   cambioPropina(valor)
   {
    this.valorpropina = valor;
   }

   ajusteModificador(evento,opcion)
  {
//    console.log(opcion);
    this.recibiocambios = true;
    this.productoparaverificar = opcion;
    opcion['seleccionactual'] = evento;
    opcion['seleccionado'] = true;
    let preciomodificadores = this.appService.getPrecioModificadores(opcion.seleccionactual);
    this.actualizaPrecioModificadores(opcion.OPCION,preciomodificadores);
  }

  actualizaPrecioModificadores(opcion,precio)
  {
    if ((this.productosimple.OPCION == opcion) && (this.productosimple['base']==1)) {this.productosimple['preciomodificadores'] = precio; } 
  }


  formatoEmail(event: any) {
    let inputChar = event.target.value.slice(-1).toLocaleLowerCase();
    if (!/^[a-z0-9@_\-\.]$/.test(inputChar)) {
      event.target.value = event.target.value.slice(0, -1);
    }
    event.target.value = event.target.value.toLocaleLowerCase();
    event.target.value = event.target.value.replace(/[^_\-a-z0-9@\.]/g, "");
  }


  stringARut(rut: string) {
    let xrut = rut;
    xrut = xrut.replace(/\-/g, "");
    xrut  = xrut .replace(/\./g, "");
    if (xrut .length > 1) {
      xrut  = xrut .slice(0, -1) + '-' + xrut .slice(-1).toUpperCase();
    }
    if (xrut .length > 3) {
      let parte1 = xrut .slice(0, -2); 
      let parte2 = '-' + xrut .slice(-1).toUpperCase();
      parte1 = parte1.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      xrut = parte1 + parte2;
    }
    return xrut;
    
  }

  formatoRut(event: any) {
    let inputChar = event.target.value.slice(-1).toLocaleLowerCase();
    if (!/^[0-9k]$/.test(inputChar)) {
      event.target.value = event.target.value.slice(0, -1);
    }
    event.target.value = this.stringARut(event.target.value);
  }



  validaEmail(email: string): boolean {
    if (email.toLowerCase().trim().length < 5) return false;
    const emailPat = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9-]{2,}$/;
    return emailPat.test(email);
  }

  validaRut(rut)
  {
    let r = this.formateaRut(rut);
    if (r.length < 6) return false;
    return this.esValidoRut(r);
  }

  dv(T)
  {
    var M = 0,
    S = 1;
    for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
  }

  esValidoRut(rutCompleto)
  {
    if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto))
    return false;
    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == 'K') digv = 'k';
    return (this.dv(rut) == digv);
  }

  formateaRut(rut)
  {
    let r1 = rut.replace(/\-/g, "");
    r1 = r1.replace(/\./g, "");

    if (r1.length > 1) {
      r1 = r1.slice(0, -1) + '-' + r1.slice(-1).toUpperCase();
    }
    return r1;
  }

}
