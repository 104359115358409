import { Component, OnInit,ElementRef, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import KeenSlider,{ KeenSliderInstance } from "keen-slider"

@Component({
  selector: 'app-menu-movil',
  templateUrl: './menu-movil.component.html',
  styleUrls: ['./menu-movil.component.scss',
  "../../../../node_modules/keen-slider/keen-slider.min.css"
]})
export class MenuMovilComponent implements OnInit {

  constructor(private router: Router) { }
  @Input() nroopciones:number;
  @Input() opciones:any;
  
  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>

  slider: KeenSliderInstance = null;
  
  ngOnInit()
  {
    //console.log(this.nroopciones);
  }

  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: false,
//      mode: "free-snap",
      mode: "free",
      slides: {
        perView: this.nroopciones,
        spacing: 30,
      },
    })
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

  buscaCategoria(categoria)
  {
    this.router.navigate(['/products/'+categoria]);
  }

  @ViewChild('dropdownRef') dropdownRef: ElementRef;
  showDropdown = false;

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  closeDropdown() {
    if (this.showDropdown) {
      this.showDropdown = false;
    }
  }

}