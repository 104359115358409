<div fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-mutedx">
    <div *ngIf="product?.availabilityCount > 0 && (type=='all' || type=='wish')">
        <bdi>
            <span *ngIf="type!='wish'" class="fw-500">Cantidad:</span> 
<!--            <button mat-icon-button matTooltip="Remove" (click)="decrement()"><mat-icon>remove</mat-icon></button> -->
            <button mat-mini-fab color="warn" matTooltip="Quitar" (click)="decrement(1)"><mat-icon>remove</mat-icon></button>
            <span style="font-size:1.2rem;font-weight:bold"> {{count}} </span>
            <button mat-mini-fab color="warn" matTooltip="Agregar" (click)="increment(1)"><mat-icon>add</mat-icon></button>

<!--             <button mat-icon-button matTooltip="Add" (click)="increment()"><mat-icon>add</mat-icon></button> -->
        </bdi>
    </div>
    <div *ngIf="type!='wish'" style="width:60px;display: inline;">
<!--         <button mat-icon-button matTooltip="Add to wishlist" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button> -->
        <div *ngIf="product?.availabilityCount > 0" (click)="addToCart(product)" class="puedehacerclick" style="height:40px;line-height:1;font-size:30px;">
            +<mat-icon style="color:black;">shopping_cart</mat-icon>
        </div>
<!--        <button mat-icon-button matTooltip="Add to compare" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button> -->
<!--        <button *ngIf="type!='all'" mat-icon-button matTooltip="Quick view" (click)="openProductDialog(product)"><mat-icon>remove_red_eye</mat-icon></button> -->
    </div>
</div> 
<div *ngIf="product?.availabilityCount == 0 && type=='all'" class="bg-warn p-1 mt-2">
    Sorry, this item is unavailable. Please choose a different one.
</div>