import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-muestra-productos',
  templateUrl: './muestra-productos.component.html',
  styleUrls: ['./muestra-productos.component.scss']
})
export class MuestraProductosComponent implements OnInit {

  @Input() product:any;
  @Input() viewOnly:boolean;
  @Output() quitarProducto = new EventEmitter<any>();

  cargando:boolean;
  constructor(private appService: AppService) {
    this.viewOnly = false;
  }

  ngOnInit(): void {

    this.product.opciones.forEach(x => {
      if (!Array.isArray(x.seleccionactual)) x.seleccionactual = [];
    });

//    console.log(this.product);
    
    this.cargando = false;
  }

  remove(product)
  {
    this.quitarProducto.emit(product);
  }

  imprimeNombre(name) {
    const decoder = new TextDecoder("utf-8");
    // Decode the UTF-8 encoded string
    const decodedString = decoder.decode(new Uint8Array([...name].map(char => char.charCodeAt(0))));
    return decodedString;
  }
}
