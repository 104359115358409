import { Injectable } from '@angular/core';
import { inflate, deflate } from 'pako';

@Injectable({
  providedIn: 'root'
})
export class ComprimeService {

  constructor() { }


  
  base64ToArrayBuffer(base64) {
    //    Buffer.from("Hello World").toString('base64'))
    
    //    var binary_string = Buffer.from(base64, 'base64').toString('binary');
        var binary_string = atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    
        ToBase64 = function (u8) {
            return btoa(String.fromCharCode.apply(null, u8));
        }
    
        desComprime(cadena)
        {
    //        const libreria = require('pako');
            let valor = this.base64ToArrayBuffer(cadena);
            const decompressed = inflate(valor,{to:'string'});
            return decompressed;
        }
    
        comprimeMensaje(mensaje)
        {
    //        const libreria = require('pako');
            let resultado = deflate(mensaje);
            let res2 = this.ToBase64(resultado);
            return res2;
        }
    
    
}
