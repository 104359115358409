<h3>Arma tu {{nombre}}</h3>
<div *ngIf="!procesando">
        <div *ngFor="let c of combo">        
            <div *ngIf="c['base'] == 1" class="principal" (click)="toggle(c)">
                <h3 class="ingrediente">{{ c.nombreopcion.trim() }} <span *ngIf="c.preciomodificadores > 0">Adic ${{c.preciomodificadores | separadormiles }}</span><span *ngIf="c.mostrar">-</span><span *ngIf="!c.mostrar">+</span></h3>
            </div>
            <div class="modif" *ngIf="c.mostrar">
                <mat-checkbox [(ngModel)]="c.seleccionado" (change)="cambioSeleccion(c)"><h4 class="modif">{{ c.descdetalle }}</h4></mat-checkbox>
            </div>
            <div *ngIf="c.mostrar && c.seleccionado && tieneModificadores(c.productodetalle)" style="background-color: azure; width:calc(100% - 10px); height:auto; position:relative; margin-left:10px; margin-top:20px; margin-bottom:20px;">
                <app-despliega-modificador [muestratitulo]="false" [productoid]="c.productodetalle" [activo]="c.seleccionado" [seleccionactual]="c['seleccionactual']" [producto]="getProducto(c.productodetalle)" (cambioModificador)="ajusteModificador($event,c)" (nroModificadores)="cambiaNumeroModificadores($event,c)"></app-despliega-modificador> 
            </div>            
        </div>
</div>