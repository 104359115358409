import { Injectable } from '@angular/core';

export class Settings {
    constructor(public name: string,
                public theme: string,
                public rtl: boolean,
                public adminSidenavIsOpened: boolean,
                public adminSidenavIsPinned: boolean,
                public adminSidenavUserBlock: boolean,
                public mainToolbarFixed: boolean,
                public modooperacion: string,
                public servidorapi: string,
                public prefijogstore: string) { }
}

@Injectable()
export class AppSettings {
    public settings = new Settings(
        'Tavelli App',  // theme name
        'tavelli',     // green, blue, red, pink, purple, grey
        false,       // true = rtl, false = ltr
        true,        // adminSidenavIsOpened
        true,        // adminSidenavIsPinned 
        true,        // adminSidenavUserBlock 
        false,        // mainToolbarFixed
        'demo', // modo de operacion
        '', 
        'https://app-tavelli.gstore.cl/qr/' // prefijo gstore
    )
}