import { C } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { max } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-despliega-modificador',
  templateUrl: './despliega-modificador.component.html',
  styleUrls: ['./despliega-modificador.component.scss']
})
export class DespliegaModificadorComponent implements OnInit {


  @Input() productoid;
  @Input() activo:boolean;
  @Input() seleccionactual:any;
  @Input() muestratitulo:boolean;
  @Input() producto:any;
  @Output() cambioEstado = new EventEmitter<any>();
  @Output() cambioModificador = new EventEmitter<any>();
  @Output() nroModificadores = new EventEmitter<number>();

  opciones:any;
  opcionesPorNumero: any;
  procesando:boolean;

  totalseleccionado=0;
  maxseleccionar=1;
  totalopciones = 0;

  constructor(private appService: AppService) { 
    this.procesando = true;
    this.muestratitulo = true;
    this.activo = false;
  }



  ordenaOpciones()
  {
    this.opciones.sort((a,b) => {
      if (a['NUMERO'] > b['NUMERO']) return 1;
      if (a['NUMERO'] < b['NUMERO']) return -1;
      return 0;
    });

  }
  
  calculaTotalOpciones()
  {
    let numeros = [];
    this.opciones.forEach( x => numeros.push(x.NUMERO));

    numeros = Array.from(new Set(numeros));

    this.totalopciones = numeros.length;
    this.nroModificadores.emit(numeros.length);
  }

  agregaDescuento(opcion)
  {
    opcion['DESCU'] = 0;
    opcion['DESCUENTO'] = 0;

    if (opcion.PRECIO >0)
    {
      opcion['DESCUENTO'] = this.appService.getDescuento(opcion.PRECIO);
      opcion['DESCU'] = this.appService.getPorcentajeDescuento();  
    }
    
  }

  ngOnInit(): void 
  {
    this.maxseleccionar = 1;
    if (this.producto != undefined)   this.maxseleccionar = this.producto.MAXMODIFI;

    this.opciones = this.appService.getModificadores(this.productoid);
    this.opciones.forEach( item => item.seleccionado = null);

    let listado = this.appService.productosbloqueados.map( item => item.codigo);
    this.opciones = this.opciones.filter( item => !listado.includes(item['CODIGOMODI']));

    this.ordenaOpciones();
    // verifica si puede elegir mas de un sabor
    this.calculaTotalOpciones();
    // el producto tiene solo una opcion de modificador y tiene como maxima seleccion mas de 1 
    // entonces puede elegir varios "sabores" del mismo modificador (hasta maxseleccionar)
    if ((this.totalopciones == 1) && (this.maxseleccionar > 1))
    {
        // ARMO UNA OPCION 2 , 3 y/o 4.
        let nuevasopciones = [];
        let nuevonumero = 2;
        for(let j=1;j<this.maxseleccionar;j++)
        {
          let a = [];
          this.opciones.forEach(x => {
            let o = Object.assign({},x);
            o.NUMERO = nuevonumero;
            a.push(o);
          });
          
          nuevasopciones.push(a);
          nuevonumero++;
        }

        nuevasopciones.forEach(x => {
          x.forEach( y => 
            this.opciones.push(y));
        });

        this.ordenaOpciones();
        this.calculaTotalOpciones();
    }

    this.valorDefault();
    //console.log(this.opciones);
      this.procesando = false;
    
  }


  getItem(codigomodi)
  {
    let salida = {};
    this.opciones.some(function(el) 
    {
      if (codigomodi == el.CODIGOMODI)
      {
        salida = el;
      }
    });
    return salida;
  }

  valorDefault()
  {
    if (Array.isArray(this.seleccionactual))
    {
      this.seleccionactual.forEach( item => 
      {
        let a = this.getItem(item.CODIGOMODI);
        if (a.hasOwnProperty('CODIGOMODI')) a['seleccionado'] = true;   
      });

    } 
  }

  recalculaSeleccionados(elemento)
  {
    let maxopciones = 1;
    if (elemento.NUMERO==1) maxopciones = elemento.MAXMODIFI;
    if (elemento.NUMERO==2) maxopciones = elemento.MAXMODIFI2;
    if (elemento.NUMERO==3) maxopciones = elemento.MAXMODIFI3;
    if (elemento.NUMERO==4) maxopciones = elemento.MAXMODIFI4;

    if (maxopciones == 0) maxopciones = 1;


    if (maxopciones == 1)
    {
      this.opciones.forEach( item => { 
        if ((item.CODIGOMODI != elemento.CODIGOMODI) && (item.NUMERO == elemento.NUMERO)) item.seleccionado = false;                                      
        this.agregaDescuento(item);
      });  
    }
    if (maxopciones > 1)
    {
      let seleccionados = 0;
      this.opciones.forEach( item => { 
        if ((item.NUMERO == elemento.NUMERO) && (item.seleccionado)) seleccionados++;                                       
      });  
      
      if (seleccionados == maxopciones)
      {
        this.opciones.forEach( item => { 
          this.agregaDescuento(item);
        });  
      }

      if (seleccionados > maxopciones)
      {
        this.opciones.forEach( item => { 
          if ((item.CODIGOMODI != elemento.CODIGOMODI) && (item.NUMERO == elemento.NUMERO)) item.seleccionado = false;                                      
          this.agregaDescuento(item);
        });  
      }
    }




    this.transmiteCambios();
  }

  cambioEstadoActivo()
  {
    this.cambioEstado.emit(this.activo);
  }

  transmiteCambios()
  {
    let modificador = [];    
    this.opciones.forEach( item => {
      if (item.seleccionado)  modificador.push(item);
    });  
    this.cambioModificador.emit(modificador);
  }


}
