<div *ngIf="!procesando" class="contenedor-modificador mat-elevation-z8">
    <h4 *ngIf="muestratitulo && totalopciones > 1" class="titulo-seleccion">Seleccionar al menos una alternativa de cada una de las {{ totalopciones }} opciones.</h4>
    <h4 *ngIf="muestratitulo && totalopciones == 1" class="titulo-seleccion">Seleccione su opción para el producto</h4>
    
    <ng-container *ngFor="let opcion of opciones; let i = index">
      <div class="tituloopcion" *ngIf="opcion.NUMERO !== opciones[i-1]?.NUMERO">
        Selecciona:
      </div>
      <div class="opciones">
        <mat-checkbox [(ngModel)]="opcion['seleccionado']" (change)="recalculaSeleccionados(opcion)">
          <span>{{ opcion.NOMBREMODI }}</span>
          <span class="precio-opcion" *ngIf="opcion.PRECIO > 0">${{opcion.PRECIO}}</span>
        </mat-checkbox> 
      </div>
    </ng-container>
  </div>