// CGO COMPUTACION INTEGRAL SPA
// SERVICIO PARA INTERACTUAR CON API REST

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { EncriptaService } from './encripta.service';
import { ComprimeService } from './comprime.service';
//import { Buffer } from 'buffer';


@Injectable({
  providedIn: 'root'
})
export class CgoApiService {

  version='1.0';
  endpointapi:string;
  servidorapi:string;
  endpointapitbank:string;
  servidorapiok:boolean;
  constructor(private encriptaService: EncriptaService,private http:HttpClient, private comprimeService: ComprimeService) 
  { 
    this.servidorapi = 'https://servidorapi';
    this.endpointapi = this.servidorapi + '/'+ 'endpointapi';
    this.endpointapitbank = this.endpointapi;
    this.servidorapiok = false;
  }
  
  setServidorApi(servidor)
  {
    this.servidorapi = servidor;
    this.endpointapi = this.servidorapi + '/' + 'apitavelli/';
    this.endpointapitbank = this.endpointapi + 'tbankv3/gomall.php';
    this.servidorapiok = true;
    this.refrescaCatalogo();

    /*
    this.selectRegistros({tabla:'cgo_test'}).subscribe( res => {
      console.log(res);
      let vuelta = this.recibeMensaje(res);
      console.log(vuelta);
    })*/

  }

  getVersionApi()
  { 
    return this.version;
  }

  enviaMensaje(xparams)
  {
    // si no esta disponible el servidor todavía.. no envio mensajes...
    if (!this.servidorapiok) return of({});

    let x1 = JSON.stringify(xparams);
    let pp = {tx1:x1};
    let xpp = this.encriptaService.codificaMensaje(pp);
    return this.http.post(this.endpointapi,xpp);    
  }

  recibeMensaje(mensaje)
  {
    let respuesta = this.encriptaService.decodificaRespuesta(mensaje);
    return respuesta;
  }

  getHora()
  {
    return this.enviaMensaje({operacion:'getHora'});
  }

  updateRegistro(xparams)
  {
      xparams['operacion'] = 'update';
      if(!xparams.hasOwnProperty('where')) xparams['where'] = {xx:0};
      return this.enviaMensaje(xparams);
  }
   /*
{
    "operacion":"insert",
    "tabla":"cgo_test",
    "campos":{id:3, valor:'otracosa'}
}

 */

  insertRegistro(xparams)
  {
      xparams['operacion'] = 'insert';
      return this.enviaMensaje(xparams);
  }

   /*
{
    "operacion":"delete",
    "tabla":"cgo_test",
    "where":{id:xxx}
}

 */

  deleteRegistro(xparams)
  {
      xparams['operacion'] = 'delete';
      if(!xparams.hasOwnProperty('where')) xparams['where'] = {xx:0};
      return this.enviaMensaje(xparams);
  }


 /*
{
    "operacion":"select",
    "tabla":"cgo_test",
    "campos":["id"],
    "where":{}
}

 */
  selectRegistros(xparams)
  {
    xparams['operacion'] = 'select';
    if(!xparams.hasOwnProperty('where')) xparams['where'] = {};
    return this.enviaMensaje(xparams);
  }

  selectBoletas(xtoken)
  {
    let xparams = {};
    xparams['operacion'] = 'verBoleta';
    xparams['tabla'] = 'vboletas';
    xparams['where'] = {token: xtoken};
    return this.enviaMensaje(xparams);
  }

  refrescaCatalogo()
  {    
    if (!this.servidorapiok) return of({});
    let n = new Date();
    let n2 = n.getTime();     
    
    return this.http.get(this.endpointapi+'r7x37.php?'+n2);
  }

  enviarBoletaEmail(xtoken,xemail)
  {
    if (!this.servidorapiok) return of({});
    let xparams = {};
    xparams['operacion'] = 'enviarBoletaMail';
    xparams['token'] = xtoken;
    xparams['email'] = xemail;
    return this.enviaMensaje(xparams);
  }

}

