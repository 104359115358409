import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwIfEmpty } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category, Product } from './app.models';
import { environment } from 'src/environments/environment';  
import { CgoApiService } from './cgo-api.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { DialogoComponent } from './shared/dialogo/dialogo.component';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { EncriptaService } from './encripta.service';
import { ComprimeService } from './comprime.service';
import { Dialogo2Component } from './pages/dialogo2/dialogo2.component';
import { UpgradeService } from './upgrade.service';

const document: any = window.document;
export class Data {
    constructor(public categories: Category[],
                public compareList: Product[],
                public wishList: Product[],
                public cartList: Product[],
                public totalPrice: number,
                public totalCartCount: number,
                public mesa: any ,
                public totalDcto: number,
                public rutdcto: string,
                public emaildcto:string,
                public nombreretira:string
                ) {    }
}

@Injectable()
export class AppService {

    catalogoactualizado = new EventEmitter<any>();
    localescargados = new EventEmitter<any>();
    categoriaactual = new EventEmitter<string>();
    cambioPropina = new EventEmitter<any>();
    cambioRetiro = new EventEmitter<any>();
    borrarCarro = new EventEmitter<any>();
    cambiocarro = new EventEmitter<any>();
    solicitaDescuento = new EventEmitter<any>();
    ultimaconexioncargada = new EventEmitter<any>();
    bloqueoscargados = new EventEmitter<any>();
    categoriasproductos = [];
    ultimaconexion = 100;
    qrscanactivo:boolean;
    dctoglobal=0;
    dctoglobal2=0;
    desplieganombrelocal = false;
    mesagenerica = false; // indica que es mesa para retiro en local solamente, no a la mesa.
    nombrelocal = '';
    nombreretira = '';
    listapreciosmesa = '-1';
    productosbloqueados = [];

    listasdeprecios = [];
    rutadirecta = ''; // se establece cuando llega al carro desde transbank.
    fullscreen = false;

    goFullScreen() {
        let elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen();
        }
      }
    

    getBloqueos()
    {
        let m = this.getMesa();
        if (m.local == '') return;
        this.getRegistros({tabla:'cgo_productosbloqueados',where:{local:m.local,empresa:m.empresa}}).subscribe( res0 => {
            let res = this.cgoApiService.recibeMensaje(res0);
            if (res.hasOwnProperty('data'))
                if (res['data'].hasOwnProperty('records'))
                    {
                        this.productosbloqueados = res['data']['records'];
                        let listado = this.productosbloqueados.map( item => item.codigo);
                        if (this.productosbloqueados.length > 0) this.bloqueoscargados.emit();
                        this.productos.forEach( item => item['bloqueado'] = listado.includes(item.id));
                        this.modificadores.forEach( item => item['bloqueado'] = listado.includes(item.CODIGOMODI));
                    }
//            this.bloqueoscargados.emit(res);
        });

    }

    getRutaDirecta(estaruta)
    {
      if (this.rutadirecta == '') return estaruta;
      return this.rutadirecta;
    } 
  
    cambiaListaPreciosMesa(listapreciosmesa)
    {
        this.listapreciosmesa = listapreciosmesa;
    }

    cambiaPreciosMesa(listapreciosmesa)
    {

    }

    setMesaGenerica(nombrelocal)
    {
        this.desplieganombrelocal = true;
        this.nombrelocal = nombrelocal + ' PARA RETIRO EN LOCAL';
        this.mesagenerica = true;
    }

    unsetMesaGenerica()
    {
        this.desplieganombrelocal = false;
        this.nombrelocal = '';
        this.mesagenerica = false;
        this.nombreretira = '';
    }

    asociaDatosCarro(s)
    {
        if (s != null)
        {
            if (s.length > 0) this.Data = JSON.parse(this.encriptaService.desencripta64(s));
            if (this.Data.mesa.id > 0) this.mesa = this.Data.mesa;
        }
    }

    public Data = new Data(
        [], // categories
        [], // compareList
        [],  // wishList
        [],  // cartList
        null, //totalPrice,
        0, //totalCartCount,
        { id:5, mesa:'m34', empresa:'0001', local: '240', nombre: 'mesa p68', token:'8cc8c43c7ca46b108be2bfdf2d81ac62', nombrelocal:'Providencia'},
        0,  
        '', // rutdcto
        '',  // emaildcto,
        ''  // nombreretira
    )
    //llaves
    private llavecarro = 'xx2ss2tv';
    private llavecatalogo = '77432xxdfs';
    private llavelocales = 'xxds7723';
    
    esProduccion()
    {
        let r = false;
        if (environment.production) r = true;
        return r;
    }

    despliegaNombreLocal()
    {
        if (this.desplieganombrelocal) return this.nombrelocal;
        return '';
    }

    getLlaveCarro()
    {
        return this.llavecarro;
    }

    getLlaveLocales()
    {
        return this.llavelocales;
    }

    getLlaveCatalogo()
    {
        return this.llavecatalogo;
    }

    getLlave(llave)
    {
        try{
           return localStorage.getItem(llave);
        }
        catch(e)
        {
            return null;
        }
    }

    quitaLlave(llave)
    {
       try{
           return localStorage.removeItem(llave);
        }
        catch(e)
        {
            return null;
        }
    }

    setLlave(llave,valor)
    {
        try{
           localStorage.setItem(llave,valor);
           return true;
        }
        catch(e)
        {
            return null;
        }
    }


    
    public url = environment.url + '/assets/data/'; 

    productos = [];
    categorias = [];
    locales = [];

    constructor(public http:HttpClient, public snackBar: MatSnackBar, private cgoApiService: CgoApiService, private dialog: MatDialog, private router: Router, private encriptaService: EncriptaService, private comprimeService: ComprimeService, private upgradeService: UpgradeService) {

        let carro = this.getLlave(this.getLlaveCarro());
//        console.log(carro)
        this.upgradeService.versionactualizada.subscribe( res => {
            this.asociaDatosCarro(carro);
            this.actualizaCatalogo();
            this.getLocales();
        });
//        console.log('ENTORNO:'+environment.entorno);

     }
    
    public getCategories(): Observable<Category[]>{
//        return this.http.get<Category[]>(this.url + 'categories.json');
        return of(this.categoriasproductos);
    }
   
    public getProducts(type): Observable<Product[]>{        
        return of(this.productos);
//        return this.http.get<Product[]>(this.url + 'products.json');
    }

    public getProductById(id): Observable<Product>{
        console.log('getProductById',id);
        
        return of(this.productos[id]);
//        return this.http.get<Product>(this.url + 'product-' + id + '.json');
    }

    public getProducto(id)
    {
        let o = this.productos.filter( x => x.id == id);
        return o;
    }

    public getBanners(): Observable<any[]>{
        return this.http.get<any[]>(this.url + 'banners.json');
    }

    public addToCompare(product:Product){
        let message, status;
        if(this.Data.compareList.filter(item=>item.id == product.id)[0]){
            message = 'The product ' + product.name + ' already added to comparison list.'; 
            status = 'error';     
        }
        else{
            this.Data.compareList.push(product);
            message = 'The product ' + product.name + ' has been added to comparison list.'; 
            status = 'success';  
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public addToWishList(product:Product){
        let message, status;
        if(this.Data.wishList.filter(item=>item.id == product.id)[0]){
            message = 'The product ' + product.name + ' already added to wish list.'; 
            status = 'error';     
        }
        else{
            this.Data.wishList.push(product);
            message = 'The product ' + product.name + ' has been added to wish list.'; 
            status = 'success';  
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    } 

    updateCartTotals()
    {
        this.Data.cartList.forEach(product=>{
            this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.newPrice);
            this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
        });

    }

    public addToCart(product:Product){
        let message, status;        
       
        this.Data.totalPrice = null;
        this.Data.totalCartCount = null;
        product.cartCount = 1;

        this.Data.cartList.push(product);
/*

        if(this.Data.cartList.filter(item=>item.id == product.id)[0]){ 
            let item = this.Data.cartList.filter(item=>item.id == product.id)[0];
            item.cartCount = product.cartCount;  
        }
        else{           
            this.Data.cartList.push(product);
        }        
        */

        this.updateCartTotals();

        message = 'El producto ' + product.name + ' se agregó a su orden.'; 
        status = 'success';   
        this.cambiocarro.emit();
//        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public removeFromCart(product) {
        const index: number = this.Data.cartList.indexOf(product);
        if (index !== -1) {
          this.Data.cartList.splice(index, 1);
          }
          this.updateCartTotals();
      }     
    


    public verifyAddToCart(product:Product,xcount=1){
//        console.log(product)
        let currentProduct = this.Data.cartList.filter(item=>item.id == product.id)[0];
        let p = this.buscaenCombos(product);    
        if (p != undefined)
        {
          if (p.id == product.id) {
    //        console.log('Producto Combo');
            this.despliegaCombo(product);
            return;
          }
    
        }

        let m = this.buscaenModificadores(product.id);
        if (m != undefined)
        {
            if (m.id == product.id)
            {
            this.despliegaModificadores(product);
            return;
            }
        }

    //    console.log('agrega carro aqui');
        if(currentProduct){
          if((currentProduct.cartCount + xcount) <= product.availabilityCount){
            product.cartCount = currentProduct.cartCount + xcount;
          }
          else{
            this.snackBar.open('-- ' + product.availabilityCount + ' -- ' + currentProduct.cartCount + ' --', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            return false;
          }
        }
        else{
          product.cartCount = xcount;
        }
        this.addToCart(product);
      }
    



    public resetProductCartCount(product:Product){
        product.cartCount = 0;
        let compareProduct = this.Data.compareList.filter(item=>item.id == product.id)[0];
        if(compareProduct){
            compareProduct.cartCount = 0;
        };
        let wishProduct = this.Data.wishList.filter(item=>item.id == product.id)[0];
        if(wishProduct){
            wishProduct.cartCount = 0;
        }; 
    }

    getValorAdicionales(product)
    {
        let valor = 0;
        if (product.hasOwnProperty('seleccionactual'))
        {
            product.seleccionactual.forEach(adicional =>{
                valor += adicional.PRECIO;
            })
        }
        return valor;
    }

    getValorBase(product)
    {
        return product.preciolista;  
    }


    public getBrands(){
        return [  
            { name: 'aloha', image: 'assets/images/brands/aloha.png' }
        ];
    }

    public getCountries(){
        return [ 
        ];
    }

    getModificadores(idproducto)
    {
        return this.modificadores.filter( item => item.id.trim() == idproducto.trim());
    }

    public getMonths(){
        return [
            { value: '01', name: 'Enero' },
            { value: '02', name: 'Febrero' },
            { value: '03', name: 'Marzo' },
            { value: '04', name: 'Abril' },
            { value: '05', name: 'Mayo' },
            { value: '06', name: 'Junio' },
            { value: '07', name: 'Julio' },
            { value: '08', name: 'Augosto' },
            { value: '09', name: 'Septiembre' },
            { value: '10', name: 'Octobre' },
            { value: '11', name: 'Noviembre' },
            { value: '12', name: 'Deciembre' }
        ]
    }

    public getYears(){
        return [ "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030" ]
    }

    public getDeliveryMethods(){
        return [
            { value: 'free', name: 'Gratis', desc: '$0.00 / Delivery in 7 to 14 business Days' }
        ];
    }

    // *** CGO COMPUTACION INTEGRAL SPA
    // *** metodos de acceso API
    servidorapi:string;
    mesa={"id":0,"nombre":"<-- Escanee el Código QR de Su Mesa"};
    public setServidor(valor)
    {
        this.servidorapi = valor;
    }

    public getRegistros(xparams)
    {
        return this.cgoApiService.selectRegistros(xparams);
    }

    public getBoletas(token)
    {
        return this.cgoApiService.selectBoletas(token);
    }


    getUrlImagenes()
    {
        return this.servidorapi + '/imagenestavelli/';
    }

    public getCatalogo()
    {

    }


    // registro de la bases de datos
    // id, empresa, local, mesa, nombre, token
    setMesa(datos)
    {
//        this.mesa = datos;
        console.log("setmesa",datos);

        this.mesa = this.armaMesaQR(datos);
        console.log("setmesa thismesa",this.mesa);
        console.log("setmesa thismesa2",this.mesa['mesa']);
        
        
        this.Data.mesa = this.mesa;
        if (this.mesa['mesa'] != 'XXX') 
        {
            console.log("entra aqui",this.productosbloqueados);
            
            this.productosbloqueados = [];
            this.getProductosBloqueados(this.mesa['empresa'],this.mesa['local']).subscribe(res0 =>{
                let res = this.cgoApiService.recibeMensaje(res0);
                console.log("res setmesa",res);
                
                if (res.hasOwnProperty('data'))
                    if (res['data'].hasOwnProperty('records'))
                        this.productosbloqueados = res['data']['records'];
                if (this.productosbloqueados.length > 0) this.bloqueoscargados.emit();
            });
        }
        console.log("entra productosbloqueados",this.productosbloqueados);
    }

    getProductosBloqueados(empresa,local)
    {
        return this.cgoApiService.selectRegistros({tabla:'cgo_productosbloqueados',where:{empresa:empresa,local:local}});
    }

    getMesa()
    {
        return this.Data.mesa;
    }

    armaMesaQR(datos)
    {
        let mesa = datos;
        if (mesa.hasOwnProperty('mesa') && mesa.hasOwnProperty('nombrelocal') && mesa.hasOwnProperty('local'))
        { 
            mesa.nombre = mesa.nombrelocal + ' - Mesa:' + mesa.mesa.toUpperCase();
        }
        else
        {
            mesa = { id:5, mesa:'m34', empresa:'0001', local: '240', nombre: 'mesa p68', token:'8cc8c43c7ca46b108be2bfdf2d81ac62', nombrelocal:'Providencia'};
        }
        return mesa;
    }


    validaMesa()
    {   
        this.mesa = this.armaMesaQR(null);
        this.setMesa(this.mesa);
        this.ajustaPrecios();
        if (this.mesa.id == 0)   this.router.navigate(['/account/qr-scan']);
    }

    validaMail(email)
    {
        const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegExp.test(email);
    }

    getMesaQROld(datos)
    {
        let mesas = [
            {id:1,nombre:'Mesa 05 - Tavelli La Reina'}, 
            {id:2,nombre:'Mesa 03 - Tavelli Manuel Montt'}, 
            {id:3,nombre:'Mesa 21 - Tavelli Viña del Mar'}, 
            {id:4,nombre:'Mesa 11 - Tavelli Parque Arauco'}];
        let numero = Math.floor(Math.random() * (3 + 1));
        return mesas[numero];
    }


    getIdCategoria(nombre) {
        console.log("nombre",nombre);
        
        let nombrebajo = nombre.toLowerCase();
        // Filtramos las categorías con el nombre en minúsculas
        let salida = this.Data.categories.filter(item => item.name.toLowerCase() === nombrebajo);
        
        // Si hay coincidencias, devolvemos el id de la primera coincidencia
        console.log("salida[id]",salida);
        
        if (salida.length > 0) return salida[0]['id'];
        
        // Si no se encuentra la categoría, devolvemos -1
        return -1;
    }
    

    public mensaje(msg)
    {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.height='250px';
      dialogConfig.width='400px';
      dialogConfig.data = { titulo:'Atencion', contenido:msg } ;
      let dialogRef = this.dialog.open(DialogoComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
  //      this.animal = result;
      });
    }

    persisteCarro()
    {
        this.recalculaCarro();
        let valor = this.encriptaService.encripta64(JSON.stringify(this.Data));
        this.setLlave(this.getLlaveCarro(),valor);    
    }

    getDataCarro()
    {
        let valor = this.getLlaveCarro();
        let datos = this.getLlave(valor);
        this.Data = JSON.parse(this.encriptaService.desencripta64(datos));
        this.recalculaCarro();
    }
    
    public recalculaCarro()
    {
            // CALCULO EL TOTAL DE PRODUCTOS POR TRAMO AGRUPABLES
            let a = [];
            this.Data.totalCartCount = 0;
            this.Data.totalPrice = 0;

            this.Data.cartList.forEach(product=>{
                this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.newPrice);
                this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
            });
    
    }

    grabaPedido(xpedido)
    {
        return this.cgoApiService.enviaMensaje({operacion:'intentoPago',pedido:xpedido});
    }

    enviarBoletaPorEmail(token,email)
    {
        return this.cgoApiService.enviarBoletaEmail(token,email);
    }

    irATransbank(codigoCarro)
    {
        let urlbase = this.cgoApiService.endpointapitbank+'?cp='+codigoCarro;
//        console.log(urlbase);
       window.location.href = urlbase; 
    }
  
    /*
    getRegistros(xparams)
    {
        if (((xparams.tabla == 'vproductostiendaon') && (!(xparams.hasOwnProperty('where')))) || (xparams.tabla == 'vtramosvalores'))
        {
//             console.log('AQUI BUSCANDO RESULTADOS');
           let n = new Date();
           let n2 = n.getTime();            
           return this.http.get(this.rutaapi+'/r7x37.php?'+n2);
        }
        let x1 = JSON.stringify(xparams);
        let pp = {tx1:x1};
        let comando = this.rutaapi+"/select.php";    
        let xpp = this.encriptaService.codificaMensaje(pp);
        return this.http.post(comando,xpp);
    }
    */

    combos = [];
    modificadores = [];
    todoslosproductos = [];

    filtraProductosyCategorias()
    {

    }
   
    filtraCategoria(cat)
    {
        let c = cat.substring(0,3);
        if (c == 'AG' ) return false;
        if (c == 'IG' ) return false;
        if (c == 'OD' ) return false;
        return true
    }


    buscaenCombos(item)
    {
        var resultado = this.combos.find(x => x.id === item.id);
        return resultado;
    }

    buscaenImagenes(item)
    {
        var resultado = this.imagenesenlinea.find( x => x === item.id.trim()+'.png');
        return resultado;
    }

    buscaenModificadores(xid)
    {
        var resultado = this.modificadores.find(x => x.id === xid);
        return resultado;
    }

    getOpcionesComboProducto(p)
    {
        var opciones = this.combos.filter(x => x.id == p.id);
        return opciones;
    }

    getModificadoresProducto(p)
    {
        var opciones = this.modificadores.filter(x => x.id.trim() == p.id.trim());
        return opciones;
    }

    despliegaCombo(producto)
    {
        let opciones = this.getOpcionesComboProducto(producto);
        opciones.sort((a,b) => {
            if (a['OPCION'] > b['OPCION'] ) return 1;
            if (a['OPCION'] < b['OPCION'] ) return -1;
            return 0;
        });
          const dialogConfig = new MatDialogConfig();

          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;

          dialogConfig.height= (viewportHeight - 60).toString() + 'px';
          dialogConfig.width= (viewportWidth - 40).toString+'px';

          dialogConfig.data = { titulo:'OPCIONES COMBO',combo:opciones} ;
          let dialogRef = this.dialog.open(Dialogo2Component, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
//            console.log(result);
            if (result.estado == 1)
            {
                let opciones = result.datos.filter(item => item.seleccionado);
                let xproducto = Object.assign({},producto);
                xproducto.opciones = opciones;
                xproducto.newPrice = this.calculaPrecio(xproducto);

                this.addToCart(xproducto);
//                console.log(xproducto);
            }
      //      this.animal = result;
          });
      
    }

    definePropina(montototal,idpropina,montopropina)
    {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height='500px';
        dialogConfig.width='700px';
        let opciones = {};
        dialogConfig.data = { titulo:'CAMBIO PROPINA',combo:opciones,cambiopropina:true,montopropina:montopropina,totalventa:montototal,idporcentajepropina:idpropina,rechazarlbl:'Cancelar',aceptarlbl:'Aceptar',} ;
        let dialogRef = this.dialog.open(Dialogo2Component, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result.estado == 1)
          {
            this.cambioPropina.emit(result.datos);
          }
        });

    }

    defineDcto(montototal)
    {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height='500px';
        dialogConfig.width='700px';
        let opciones = {};
        dialogConfig.data = { titulo:'SOLICITA DESCUENTO',dialogodescuento:true,rechazarlbl:'Cancelar',aceptarlbl:'Aceptar',rutdcto:this.Data.rutdcto,emaildcto:this.Data.emaildcto} ;
        let dialogRef = this.dialog.open(Dialogo2Component, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result.hasOwnProperty('estado'))
                {
                    if (result.estado == 1)
                        {
                            this.solicitaDescuento.emit(result);
                        }
                }
        });

    }




    defineRetiroPor()
    {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height='500px';
        dialogConfig.width='700px';
        let opciones = {};
        dialogConfig.data = { titulo:'NOMBRE DE QUIEN RETIRA PEDIDO',dialogoretiro:true,rechazarlbl:'Cancelar',aceptarlbl:'Aceptar',nombreretira:this.nombreretira} ;
        let dialogRef = this.dialog.open(Dialogo2Component, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result.estado == 1)
          {
            if (result.datos.nombreretira.trim().length > 0) this.nombreretira = result.datos.nombreretira.trim();
            this.cambioRetiro.emit(result.datos.nombreretira);
          }
        });
    }



    confirmaBorraCarro()
    {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height='500px';
        dialogConfig.width='700px';
        let opciones = {};
        dialogConfig.data = { titulo:'Confirma Borrar Pedido',dialogoborrarcarro:true,rechazarlbl:'Cancelar',aceptarlbl:'Sí, Borrar Carro'} ;
        let dialogRef = this.dialog.open(Dialogo2Component, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result.estado == 1)
          {
            this.borrarCarro.emit();
          }
        });
    }



    getPrecioModificadores(listamodificadores)
    {
        let precio = 0;
        if (listamodificadores.constructor === Array) {
            if (listamodificadores != undefined) listamodificadores.forEach(x => precio = precio + x.PRECIO);
        }
//        console.log('PRECIO MODIFICADOR : '+precio);
        return precio;
    }

    calculaPrecio(producto)
    {
        let precio = producto.preciolista;
        producto.opciones.forEach( x => {if (x.seleccionado) {  precio = precio + this.getPrecioModificadores(x.seleccionactual); }});

//        console.log('PRECIO CALCULADO:'+precio);
        return precio;
    }



    calculaPrecioSimple(producto)
    {
        let precio = producto.preciolista;
        producto.opciones.forEach( x => {if (x.seleccionado) {  precio = precio + x.PRECIO }});
        return precio;
    }

    despliegaModificadores(producto)
    {
        let opciones = this.getModificadoresProducto(producto);
        opciones.sort((a,b) => {
            if (a['OPCION'] > b['OPCION'] ) return 1;
            if (a['OPCION'] < b['OPCION'] ) return -1;
            return 0;
        });

          const dialogConfig = new MatDialogConfig();

          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;

          dialogConfig.height= (viewportHeight - 60).toString() + 'px';
          dialogConfig.width= (viewportWidth - 40).toString+'px';


          dialogConfig.data = { titulo:'OPCIONES X',combo:opciones, productoid:producto.id, despliegacombo: false, productosimple: producto} ;
          let dialogRef = this.dialog.open(Dialogo2Component, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            if (result.estado == 1)
            {
                let opciones = result.datos.filter(item => item.seleccionado);
                let comentario = result.comentario;
                let xproducto = Object.assign({},producto);
                xproducto.opciones = opciones;
               xproducto.newPrice = this.calculaPrecioSimple(xproducto);

//                xproducto.newPrice = this.calculaPrecio(xproducto);

                if (comentario != undefined) 
                    if (comentario.trim().length > 0) xproducto.opciones.push(this.agregaComentario(xproducto,comentario));
                xproducto.seleccionactual = xproducto.opciones;
                this.addToCart(xproducto);
            }
          });
      
    }


    agregaComentario(xproducto,xcomentario)
    {
  //    let com = 
      let obj = {id:xproducto.id,name:"Comentario",CODIGOMODI:"999900000",CODIGOPROD:xproducto.id,MODIFICADO:null,NOMOSTRAR:0,NUMERO:1,OBSERVA:0,PRECIO:0,SALE:0,MENSAJE:1,NOMBREMODI:xcomentario,seleccionado:true};       
      return obj;
    }
  


    copiaCatalogo(xc)
    {

        let res = JSON.parse(this.comprimeService.desComprime(xc));
        this.todoslosproductos = res['records'];
        this.productos = res['records'].filter(item => item.newPrice > 100);
        this.productos = this.productos.filter(item => item.categoryId != null);
        this.productos = this.productos.filter(item => this.filtraCategoria(item.categoryId));
//        this.productos = this.productos.filter(item => this.buscaenImagenes(item));
        this.productos = this.productos.filter(item => item.FOTO1 != null);
//        console.log(this.productos);
        this.productos = this.productos.filter(item => item.FOTO1.toString().trim() == '1');
        this.productos.forEach( x => x['preciolista'] = x['newPrice']);
        this.armaCategorias(this.productos);

//        this.productos = this.productos.filter(item => this.buscaenCombos(item));
        this.combos = res['records2'];
        this.modificadores = res['records3'];
        this.listasdeprecios = res['records4'];

        this.ajustaPrecios();
        this.catalogoactualizado.emit(); // le informa a todos que se actualizo el catálogo.
//        console.log(this.combos);
    }

    getPrecioProducto(codigo)
    {
        const lp = this.listapreciosmesa.trim();
        let preciofinal = 0;
        let articulo = this.listasdeprecios.find(x => x.CODIGOPROD.trim() === codigo && x.CODLISTA.trim() === lp);
        if (articulo)
        {
            if (lp != '-1') preciofinal = articulo.PRECIO1 ? articulo.PRECIO1 : 0; 
        }
        return preciofinal;
    }

    ajustaPrecios()
    {
        this.productos.forEach( x => {
            let precio = this.getPrecioProducto(x.id.trim());
            x['preciolista'] = precio;
            x['oldPrice'] = precio; 
            x['newPrice'] = 1000;
        }   
        );

        const prod1 = this.productos.filter(item => item.newPrice > 0);
        
        const cat1 = prod1.map(item => item.GRANGRUPO.trim());
        
        const b = [... new Set(cat1)];

        this.categoriasproductos = [];
        b.forEach(x => this.categoriasproductos.push(this.limpiaNombreCategoria(x)));
        
        this.categoriasproductos = this.categoriasproductos.filter(item => item != 'Agregados');
        this.categoriasproductos.push('Todos los Productos');
        this.getBloqueos();
    }


    copiaLocales(xc)
    {
        let locales = JSON.parse(this.comprimeService.desComprime(xc));
        this.locales = locales;
    }


    limpiaNombreCategoria(nombre)
    {
        let nuevo = nombre.trim();
        const regex = /\//i;
        nuevo = nuevo.replace(regex,'-');
        const regex2 = / /i;
        nuevo = nuevo.replace(regex,'');
        return nuevo;
    }

    existeCategoria(categoria,arreglo)
    {
        for(let a=0;a<arreglo.length;a++)
        {
            if (arreglo[a]['categoria'] == categoria) return true;
        }
        return false;
    }

    clear()
    {
        this.Data.cartList.forEach(product=>{
            this.resetProductCartCount(product);
          });
          this.Data.cartList.length = 0;
          this.Data.totalPrice = 0;
          this.Data.totalCartCount = 0;
          this.Data.rutdcto = '';
          this.Data.emaildcto = '';
          this.Data.nombreretira = '';
          this.persisteCarro();         
    }

    // armaLas Categorias en funcion de los productos presentados.
    armaCategorias(productos)
    {
        let base = [];
        productos.forEach(x => { 
            let xcategoria = this.limpiaNombreCategoria(x.GRANGRUPO); 
            if (!this.existeCategoria(xcategoria,base)) base.push({categoria:xcategoria,orden:x.ORDEN})
        });

        let base2 = [... new Set(base)]
        base2.sort((a,b) => {
            if (a.orden > b.orden) return 1;
            if (b.orden > a.orden) return -1;
            return 0;
        });
        
        this.categorias = [];
        base2.forEach(x => {
            this.categorias.push(x.categoria);
        });
        
       this.categorias.push('Todos los Productos');
       /* this.Data.categories = [... new Set(base2)];
       console.log("DAtacategorias",this.Data.categories); */
       
       this.categorias = [... new Set(base)];
    }

    actualizaCatalogo()
    {
        let xc1 = this.getLlave(this.getLlaveCatalogo());
        
        if (xc1 != null)  if (xc1.length > 0) this.copiaCatalogo(xc1);
        
        this.cgoApiService.refrescaCatalogo().subscribe( res0 => {
            if (res0.hasOwnProperty('xmensaje'))
            {
                let xc = res0['xmensaje']
                this.setLlave(this.getLlaveCatalogo(),xc);
                this.copiaCatalogo(xc);
            }
        });            
    }

    buscaMesa(token)
    {
        let mesa = {};
        this.locales.forEach( x=> { if (x.token.trim() == token.trim()) { mesa = x }} );
        return mesa;
    }

    getLocales()
    {
        let xllave = this.getLlaveLocales();
        let xc1 = this.getLlave(xllave);
        if (xc1 != null)  if (xc1.length > 0) this.copiaLocales(xc1);
        this.getRegistros({tabla:'cgo_locales'}).subscribe( res0 => {
            let res = this.cgoApiService.recibeMensaje(res0);
            if (res.hasOwnProperty('data')) {
                let data = res['data'];
                if (data.hasOwnProperty('records')) {
                    this.locales = data['records'];
                    this.setLlave(xllave,this.comprimeService.comprimeMensaje(JSON.stringify(this.locales)));
                }
            }
            this.localescargados.emit();
        });
    }

    getActivo(xtoken)
    {
        this.ultimaconexion = 100;
//        console.log(xtoken);
        let xxtoken = xtoken.token;
        this.getRegistros({tabla:'vultimaconexion',where:{token:xxtoken}}).subscribe( res0 =>{
            let res = this.cgoApiService.recibeMensaje(res0);
            if (res.hasOwnProperty('data')) {
                let data = res['data'];
                if (data.hasOwnProperty('records')) {
                    this.ultimaconexion = data['records'][0]['tiempoapagado'];
                    this.dctoglobal = (data['records'][0]['descuento']*1 > 0) ? data['records'][0]['descuento'] : 0;
                    this.dctoglobal2 = (data['records'][0]['descuento2']*1 > 0) ? data['records'][0]['descuento2'] : 0;
                    let mesa = data['records'][0]['mesa'].toString().toUpperCase();
                    this.unsetMesaGenerica();
                    if (mesa == 'X01') {
                        this.setMesaGenerica(data['records'][0]['nombrelocal']);
                    }
                    this.ultimaconexioncargada.emit();
                    this.listapreciosmesa = data['records'][0]['listaprecios'];
                    this.ajustaPrecios();
                    if (this.ultimaconexion > 90) this.noPuedeContinuar();
                }
            }
        }); 
        //, () => { this.ultimaconexion = 100; this.ultimaconexioncargada.emit();
        //}, () => {this.ultimaconexioncargada.emit();});
    }

    noPuedeContinuar()
    {
        this.mensaje('No es posible realizar pedidos por ahora.');
    }

    aplicaDescuento(p,valor,porcentaje)
    {
        let valorfinal = valor; 
        if (valor > 0) {
            p.DESCUENTO = Math.floor(valor * porcentaje*1.0/100)
            valorfinal = valor - p.DESCUENTO;
            p.DESCU = this.dctoglobal;
        }
        return valorfinal;
    }


    aplicaDescuento2(valor)
    {
        let valorfinal = valor; 
        if ((valor > 0) && (this.dctoglobal2 > 0)) 
        {
            let dcto = Math.floor(valor * this.dctoglobal2*1.0/100)
            valorfinal = valor - dcto;
        }
        return valorfinal;
    }


    getDescuento(valor)
    {
        let valordescuento = 0; 
        if (valor > 0) {
            valordescuento =  Math.floor(valor * this.dctoglobal*1.0/100)
        }
        return valordescuento;
    }

    getPorcentajeDescuento()
    {
        return this.dctoglobal;
    }


    imagenesenlinea = [];

} 