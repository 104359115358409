import { Component, OnInit, Input, EventEmitter,Output } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-despliega-combo',
  templateUrl: './despliega-combo.component.html',
  styleUrls: ['./despliega-combo.component.scss']
})
export class DespliegaComboComponent implements OnInit {

  @Input() combo;
  @Output() cambioModificador = new EventEmitter<any>();
  @Output() cambioNumeroOpciones = new EventEmitter<number>();
  procesando:boolean;
  nombre:string;

  constructor(private appService: AppService) { 
    this.procesando = true;
  }

  ngOnInit(): void 
  {
    this.nombre = this.combo[0]['name'];
    this.combo.sort((a,b) => {
        if (a['OPCION'] > b['OPCION']) return 1;
        if (a['OPCION'] < b['OPCION']) return -1;
        return 0;
    });

    let opcionant = '';
    this.combo.forEach( item => {  
      item['OPCION'] != opcionant ? item['base'] = 1 : item['base'] = 0;
      item['modificadores'] = {};
      item['abierto'] = false;
      opcionant = item['OPCION'];
      item['seleccionado'] = false;
      item['mostrar'] = true;
      item['seleccionactual'] = {};
      item['preciomodificadores'] = 0;
      item['nromodificadores'] = -1;
    });


    let listado = this.appService.productosbloqueados.map( item => item.codigo);
    this.combo = this.combo.filter( item => !listado.includes(item['productodetalle']));
    this.procesando = false;
  }

  toggle(c)
  {
    let estado = !c.mostrar;
    let opcion = c['OPCION'];
    this.combo.forEach( item => {  
      if (item['OPCION'] == opcion)
      {
//        item['seleccionado'] = false;
        item['mostrar'] = estado;
      }
    });    
  }

  cambiaNumeroModificadores(numero,c)
  {
    c['nromodificadores'] = numero;
  }

  tieneModificadores(productoid)
  {
    let resultado = {};
    resultado = this.appService.buscaenModificadores(productoid);
    if (resultado == undefined) return false;
    if (resultado.hasOwnProperty('id')) return true;
    return false;
  }

  getProducto(productoid)
  {
    return this.appService.getProducto(productoid)[0];
  }



  cambioSeleccion(elemento)
  {
    this.combo.forEach( item => { 
      if ((item.productodetalle != elemento.productodetalle) && (item.OPCION == elemento.OPCION)) item.seleccionado = false;
    });    
//    console.log(this.combo);
//    console.log(elemento);
/*    <!-- 		  	"CODIGOPROD": id,
			"CODIGOMODI": productodetalle,
			"NOMBREMODI": descdetalle,
*/
    // SI ES PARTE DE UN COMBO / MENU
    if (elemento.hasOwnProperty('DETPLAMEN'))
    {
//      console.log('es combo');
      if (!Array.isArray(elemento.seleccionactual))
      {
//        console.log('no tiene selecciona actual')
       if (!elemento.hasOwnProperty('CODIGOPROD')) elemento['CODIGOPROD'] = elemento.id;
       if (!elemento.hasOwnProperty('CODIGOMODI')) elemento['CODIGOMODI'] = elemento.productodetalle;
       if (!elemento.hasOwnProperty('NOMBREMODI')) elemento['NOMBREMODI'] = elemento.descdetalle;
//       console.log(elemento);
      }
    }
  }


  actualizaPrecioModificadores(opcion,precio)
  {
    this.combo.forEach( xopcion =>  {if ((xopcion.OPCION == opcion) && (xopcion['base']==1)) xopcion['preciomodificadores'] = precio; }); 
  }
  
  ajusteModificador(evento,opcion)
  {
//    console.log('RECIBE CAMBIO OPCION MODIFICADOR');
    opcion['seleccionactual'] = evento;
    opcion['seleccionado'] = true;
    let preciomodificadores = this.appService.getPrecioModificadores(opcion.seleccionactual);
    this.actualizaPrecioModificadores(opcion.OPCION,preciomodificadores);
//    console.log(opcion);
//    console.log(opcion.seleccionactual);
//    console.log(this.combo);
  }

}
