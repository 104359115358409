import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatofecha'
})
export class FormatofechaPipe implements PipeTransform {
// de Y-m-d a d/m/Y
  transform(input:any):string {
    var x = ''+input;
    try {
    let a = x.substring(0,4);
    let b = x.substring(5,7);
    let c = x.substring(8,10);
    return c+'/'+b+'/'+a;
    }
    catch(e)
    {
      return input;
    }
  }

}
