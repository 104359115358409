<!--<div style="display:block;width:100%">
<div class="keen-slider" #sliderRef>
    <div *ngFor="let o of opciones" class="keen-slider__slide" (click)="buscaCategoria(o)">{{o}}</div>
</div>
</div>-->
<div class="dropdown">
    <button class="dropbtn" (click)="toggleDropdown()">Menú</button>
    <div id="myDropdown" [ngClass]="{'dropdown-content': true, 'show': showDropdown}" (click)="closeDropdown()">
        <div *ngFor="let o of opciones" (click)="buscaCategoria(o)"><a>{{o}}</a></div>
    </div>
</div>