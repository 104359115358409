import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatonumero'
})
export class FormatonumeroPipe implements PipeTransform {
    transform(numero) {
        if (isNaN(numero)) return numero;
        let a = (''+Math.round(numero*1)).trim(); // solo dejo la parte entera
        let nn = '';
        let sep = '';
        let i = 0;
        for(let j=a.length-1;j>=0;j--)
        {
            i++;
            sep = '';
            if (i == 3) { sep = '.'; i=0;}
            if (j == 0) sep = '';
            nn = sep + a[j] + nn;
        }
        return nn;
    }
}