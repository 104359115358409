import { Component, OnInit, Input, Output,EventEmitter, ChangeDetectorRef, ViewChild,ElementRef } from '@angular/core';

@Component({
  selector: 'app-propina',
  templateUrl: './propina.component.html',
  styleUrls: ['./propina.component.scss']
})
export class PropinaComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef,
    ) { 
    this.cargando = true;
    this.otromonto = false;
  }
  @Input() totalventa;
  @Input() idporcentaje;
  @Input() montopropina;
  @Output() cambioPropina = new EventEmitter<any>();
  cargando:boolean;
  otromonto:boolean;
  porcentajes = [
    {id:0,name:'Sin Propina',seleccionado:false,monto:0},
    {id:5,name:'5%',seleccionado:false,monto:0},
    {id:10,name:'10%',seleccionado:false,monto:0},
    {id:15,name:'15%',seleccionado:false,monto:0},
    {id:-1,name:'Otro Valor',seleccionado:false,monto:0}];

    @ViewChild('otrapropina', { static: false }) otrapropina: ElementRef;

    selectInput(input: HTMLInputElement) {
      input.select();
    }

  ngOnInit(): void {
    this.porcentajes.forEach(x => {
      if (x.id > 0) x.monto = Math.floor(this.totalventa * (x.id / 100));
      if (x.id == this.idporcentaje) { x.seleccionado = true; if (x.id == -1) {this.otromonto = true; }}
      if (x.id == -1) { x.monto = (this.montopropina != undefined ? this.montopropina : 0);}
    });
    this.cargando = false;
  }

  recalculaPropina(valor)
  {
    this.otromonto = false;
    this.porcentajes.forEach( x => 
      { 
        if (x.id == valor.id) { x['seleccionado'] = true ; if (x['id'] == -1) this.otromonto = true; }
        if (x.id != valor.id) { x['seleccionado'] = null ; }
      });
      this.cdr.detectChanges();
      this.mandaMonto();
  }

  restrictToNumbers(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const numericInput = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    this.montopropina = numericInput; // Update the bound variable with the filtered value
    this.cambioMonto();
  }

  cambioMonto()
  {
    this.porcentajes.forEach( x => {
      if (x.id == -1) x.monto = this.montopropina * 1;
    })
    this.mandaMonto();
  }
  
  mandaMonto()
  {
    let propina = {id:0,monto:0};
    this.porcentajes.forEach( x => 
      { 
        if (x.seleccionado) { 
        this.idporcentaje = x.id;
        this.montopropina = (x.monto != undefined ? x.monto : 0);
        propina = {id:x.id,monto:this.montopropina }
        }
      }
      );

    this.cambioPropina.emit(propina);
  }


  cambiaMonto(monto)
  {
    this.porcentajes.forEach( x => 
      { 
        if (x.id != -1) { x.monto = monto; }
      });
    this.mandaMonto();
  }



}
