<div style="display:block; text-align:center; padding:0px; margin:0px;">
    <app-despliega-combo *ngIf="combo.length > 0 && despliegacombo && !dialogopropina && !dialogoretiro" [combo]="combo" (comboModificado)="getSeleccion($event)"></app-despliega-combo>
    <app-despliega-modificador *ngIf="combo.length > 0 && !despliegacombo && !dialogopropina && !dialogoretiro" [productoid]="productoid" [producto]="productosimple" [activo]="true" [seleccionactual]="{}" (cambioModificador)="ajusteModificador($event,productosimple)" (nroModificadores)="cambiaTotalOpciones($event)"></app-despliega-modificador> 
    <div *ngIf="combo.length > 0 && !dialogopropina && !dialogoretiro">
      <b>Comentario</b>
      <textarea class="comentario" [(ngModel)]="comentario" placeholder="Añade un comentario..."></textarea>
    </div>
    <h3 *ngIf="dialogopropina">MONTO PROPINA</h3>
    <app-propina *ngIf="dialogopropina" [totalventa]="totalventa" [montopropina]="montopropina" [idporcentaje]="idporcentajepropina" (cambioPropina)="cambioPropina($event)"></app-propina> 
  
    <div *ngIf="dialogoborrarcarro" style="text-align:center; font-weight:600">
      ¿Está Ud. seguro(a) que desea borrar todos los productos del carro?
    </div>
    <div *ngIf="dialogoretiro">
      <b>Nombre de quien retira</b>
      <textarea class="comentario" [(ngModel)]="comentario" placeholder="Introduce el nombre..."></textarea>
    </div>
  
    <div *ngIf="dialogodescuento" style="text-align:left; padding-left:15px;">
      <b>Por favor Ingrese los datos para el descuento:</b>
      <div style="margin: 10px 0;">
        <b>RUT:</b>
        <input type="text" [(ngModel)]="rut" (keyup)="formatoRut($event)">
      </div>
      <div style="margin: 10px 0;">
        <b>Email:</b>
        <input type="text" [(ngModel)]="email" (keyup)="formatoEmail($event)">
      </div>
    </div>
  
    <div *ngIf="dialogodescuento" style="text-align:left; padding-left:15px; margin-top:10px;">
      <b>Se verificará que Ud. es funcionario de CLC</b>
    </div>
  
    <div mat-dialog-actions style="text-align:center;">
      <button mat-raised-button *ngIf="closebtn" class="boton" color="primary" (click)="close()">{{ cerrarlbl }}</button>
      <button mat-raised-button *ngIf="okbtn" class="boton" color="accent" (click)="ok()">{{ aceptarlbl }}</button>
      <button mat-raised-button *ngIf="nokbtn" class="boton" color="warn" (click)="nok()">{{ rechazarlbl }}</button>
    </div>
  </div>