import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: Product;
  @Input() group: any;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count:number = 1;
  public align = 'center center';
  constructor(public appService:AppService, public snackBar: MatSnackBar) { }


  updateCount()
  {
    if(this.product){
      if(this.product.cartCount > 0)
      {
        this.count = this.product.cartCount;
      }
    }  

    if (this.group)
    {
      this.product = this.group.seleccionados[0];
      this.count = this.group.cantidad;
    }
  }

  ngOnInit() {
    this.updateCount();
    this.layoutAlign(); 
  }

  public layoutAlign(){
    if(this.type == 'all'){
      this.align = 'space-between center';
    }
    else if(this.type == 'wish'){
      this.align = 'start center';
    }
    else{
      this.align = 'center center';
    }
  }


  public increment(count)
  {
    let p = this.product;
    if (this.group) {p = Object.assign({},this.group.seleccionados[0]);}
    this.appService.verifyAddToCart(p,1);
    this.updateCount();
    this.changeQuantity(1);
  }

  public decrement(count)
  {  
    if ((this.group.mod) || (this.group.escombo)) 
    {
      if (this.count > 1)
      {
        this.appService.mensaje('Seleccione el producto de la lista para quitarlo.');
        this.group.verdetalle = true;
        return;
      }
    }

    if(this.count > 0) 
    {
      this.appService.removeFromCart(this.group.seleccionados[this.count-1]);
      this.count--;
      this.changeQuantity(1);
    }
  }

  public addToCompare(product:Product){
    this.appService.addToCompare(product);
  }

  public addToWishList(product:Product){
    this.appService.addToWishList(product);
  }


  public addToCart(product:Product)
  {
    this.appService.verifyAddToCart(product,1);
    this.updateCount();
  }

  public addToCartOLD(product:Product){
    // console.log(product)
    let currentProduct = this.appService.Data.cartList.filter(item=>item.id == product.id)[0];
    let p = this.appService.buscaenCombos(product);    

    if (p != undefined)
    {
      if (p.id == product.id) {
//        console.log('Producto Combo');
        this.appService.despliegaCombo(product);
        return;
      }

      let m = this.appService.buscaenModificadores(product.id);
      if (m.id == product.id)
      {
        this.appService.despliegaModificadores(product);
      }
    }
//    console.log('agrega carro aqui');
    if(currentProduct){
      if((currentProduct.cartCount + this.count) <= this.product.availabilityCount){
        product.cartCount = currentProduct.cartCount + this.count;
      }
      else{
        this.snackBar.open('-- ' + this.product.availabilityCount + ' -- ' + currentProduct.cartCount + ' --', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return false;
      }
    }
    else{
      product.cartCount = this.count;
    }
    this.appService.verifyAddToCart(product);
  }

  public openProductDialog(event){
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value){
      this.onQuantityChange.emit(value);
  }

}