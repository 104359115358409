import { Injectable } from '@angular/core';
import { VersionApp } from './version';
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpgradeService {

  versionlocal = new VersionApp();
  versionservidor:string;
  modooperacion:string;
  servidorapi:string;
  versionactualizada = new EventEmitter<void>();
  constructor(private http:HttpClient) { }

  getVersionLocal()
  {
    return this.versionlocal.g_version;
  }

  getVersionServidor()
  {
    return this.versionservidor;
  }

  getServidorApi()
  {
    return this.servidorapi;
  }

  actualizaVersionServidor()
  {
    let n = new Date();
    let n2 = n.getTime();
    this.http.get('assets/version-actual.json?'+n2).subscribe(
      res=>{
        this.versionservidor = res['version'];
        this.modooperacion = res['modo'];
        this.servidorapi = res['servidor'];
        this.indicaVersiones();
        this.versionactualizada.emit();
      });
  }

 indicaVersiones()
 {
   console.log('Version Servidor:' + this.versionservidor + ' Servidor Api:' + this.servidorapi);
   console.log('Version Local: '+this.versionlocal.g_version);
 }

}
