import { isPlatformBrowser } from '@angular/common';
import { HostListener,Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { TranslateService } from '@ngx-translate/core';
import { UpgradeService } from './upgrade.service';
import { CgoApiService } from './cgo-api.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public startY: number = 0;

  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.startY = event.touches[0].pageY;
  }

  @HostListener('document:touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    if (event.touches[0].pageY < this.startY && window.scrollY === 0) {
      event.preventDefault();
    }
  }

  loading: boolean = false;
  public settings: Settings;
  constructor(public appSettings: AppSettings, 
              public router: Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              public translate: TranslateService,
              public upgradeService: UpgradeService,
              private cgoApiService: CgoApiService){
    this.settings = this.appSettings.settings;
    translate.addLangs(['en','de','fr','ru','tr']);
    translate.setDefaultLang('en'); 
    translate.use('en');
    this.revisaVersionServidor();
  }

  revisaVersionServidor()
  {
    this.upgradeService.actualizaVersionServidor();
    this.upgradeService.versionactualizada.subscribe( res => {
      this.appSettings.settings.modooperacion = this.upgradeService.modooperacion;
      this.appSettings.settings.servidorapi = this.upgradeService.servidorapi;
      this.cgoApiService.setServidorApi(this.upgradeService.servidorapi);
        if (this.upgradeService.getVersionLocal() != this.upgradeService.getVersionServidor())
        {
          console.log('recargando version....');
//          alert('Version en el servidor diferente de la local.. se actualizará automáticamente');
          window.location.reload();
        }
    });
  }


  ngOnInit() {
   // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh   
   
    this.cgoApiService.refrescaCatalogo();
  }




  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        } 
      }
    })  
  }
}
