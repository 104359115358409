import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { AppService } from 'src/app/app.service';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-qr-scan',
  templateUrl: './qr-scan.component.html',
  styleUrls: ['./qr-scan.component.scss']
})
export class QrScanComponent implements OnInit {

  constructor(private appService: AppService, private router: Router, private appSettings: AppSettings) {  
    this.appService.setMesa({});
   }
  output:any;

//  @ViewChild('action', {read:false, static: false}) xscanner:any;
  @ViewChild('action', { static: false }) action: NgxScannerQrcodeComponent;

  
  ngOnInit(): void {
    let token = "821a48be7ae20798412cfeafc64f9972";
    token = '68c391e6224ce8cf19704ac9f06a71f6'; // clc para llevar
    // token = 'eaa72768729d049fb0c81b24f85f9d57'; // clc m01
    //token = '95e87ed85587243add70406b40d3c6e7';
    //token = '95e87ed85587243add70406b40d3c6e7';
    //token = '95e87ed85587243add70406b40d3c6e7'; // lareina p68
    //token = '001261x01';
    this.appService.qrscanactivo = true;
    this.appService.actualizaCatalogo();
    if (!this.appService.esProduccion())
    {
      //let prefijo2 = 'http://localhost:4200/qr/';
      let prefijo2 = 'https://test-apptavelli.gstore.cl/qr/';//TODO: cambiar a produccion
      window.location.replace(prefijo2+token);  
    }
  }

  llegoInformacion(datos)
  {
    this.output = datos;
    if (datos.length > 0)
    {
//      console.log(datos);
        let prefijo = this.appSettings.settings.prefijogstore;
        let token = datos.substring(prefijo.length,datos.length);
//        console.log(token);
        if (token.length == 32)
        {
          this.appService.qrscanactivo = false;
          this.appService.mesa['id'] = 0;
          // prefijo2 = 'http://localhost:4200/qr/';
          let prefijo2 = 'https://test-apptavelli.gstore.cl/qr/';
          window.location.replace(prefijo+token);
        }
        /*
        let xmesa = { id:1, mesa:'M15', empresa:'001', local: '001', nombre: 'Mesa1 definido', token:'xxxxx', nombrelocal:'TAVELLI PROVIDENCIA'};
        this.appService.setMesa(xmesa);
        this.appService.mensaje('<div style="text-align:center;width:300px;"><b>Ud. se encuentra en :<br>'+this.appService.mesa.nombre+'</b></div>');
//        this.appService.mensaje('Ha definido la mesa correctamente');
        this.appService.qrscanactivo = false;
        this.router.navigate(['/']);
        */
    }
  }

  ngAfterViewInit()
  {
//    console.log(this.action);
    if (!this.action.isStart) { this.action.toggleCamera(); }
  }

  ngOnDestroy()
  {
    if (this.action.isStart) { this.action.toggleCamera(); }
    this.appService.qrscanactivo = false;
  }
}
