<div class="top-menu">    

    <!-- 
    <a mat-button [matMenuTriggerFor]="currencyMenu" #currencyMenuTrigger="matMenuTrigger">
        {{currency}}<mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #currencyMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
        <span (mouseleave)="currencyMenuTrigger.closeMenu()">
            <button mat-menu-item *ngFor="let cur of currencies" (click)="changeCurrency(cur)">
                <span>{{cur}}</span>
            </button>
        </span>
    </mat-menu> 
    -->
<!--    <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger"> -->
         
        <a routerLink="account/qr-scan"><mat-icon class="mat-icon-sm account-icon">settings</mat-icon>
        <!--     <span fxShow="false" fxShow.gt-sm>{{ 'SCAN QR' | translate }}</span>        -->
        </a> 
        <span>{{appService.despliegaNombreLocal()}}</span>
        <!---          <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
  </a> -->

  <!-- 
    <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
        <span (mouseleave)="accountMenuTrigger.closeMenu()">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">                   
                <img src="assets/images/others/user.jpg" alt="user-image" width="50">
                <p>Emilio Verdines <br> <small>emilio_v <span *ngIf="settings.rtl">&rlm;</span>(152<mat-icon class="mat-icon-xs">star</mat-icon>)</small></p>
            </div>
            <div class="divider"></div>
            <a mat-menu-item routerLink="account/qr-scan"> 
                <mat-icon class="mat-icon-sm">settings</mat-icon>
                <span>Scan QR-Local/Mesa</span> 
            </a>
            <a mat-menu-item routerLink="/account"> 
                <mat-icon class="mat-icon-sm">settings</mat-icon>
                <span>Account Settings</span> 
            </a>
            <a mat-menu-item routerLink="/compare" fxHide="false" fxHide.gt-sm>
                <mat-icon class="mat-icon-sm">compare</mat-icon>
                <span>Compare <span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.compareList.length}})</span> 
            </a>
            <a mat-menu-item routerLink="/wishlist" fxHide="false" fxHide.gt-sm>
                <mat-icon class="mat-icon-sm">favorite</mat-icon>
                <span>Wishlist <span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.wishList.length}})</span>
            </a>
            <a mat-menu-item routerLink="/"> 
                <mat-icon class="mat-icon-sm">lock</mat-icon>
                <span>Lock screen</span> 
            </a>
            <a mat-menu-item routerLink="/"> 
                <mat-icon class="mat-icon-sm">help</mat-icon>
                <span>Help</span> 
            </a>
            <div class="divider"></div>
            <a mat-menu-item routerLink="/sign-in"> 
                <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                <span>{{ 'LOGOUT' | translate }}</span> 
            </a>
        </span>
    </mat-menu>    
    -->
</div>  