import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-mesadirecta',
  templateUrl: './mesadirecta.component.html',
  styleUrls: ['./mesadirecta.component.scss']
})
export class MesadirectaComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private appService:AppService) { }

  buscandomesa = true;
  sigue = true;
  ngOnInit(): void {
    this.appService.localescargados.subscribe( res => {
      this.buscaMesa();
    });          
    this.appService.getLocales();
}

  buscaMesa()
  {
      this.activatedRoute.params.subscribe(params => {
        if (params['datosmesa'] != "")
        {
            let mesa = this.appService.buscaMesa(params['datosmesa']);
              this.appService.setMesa(mesa);
              this.appService.getActivo(mesa);
              this.appService.ajustaPrecios();
              this.buscandomesa = false;
             // this.appService.mensaje('<div style="text-align:center;width:300px;"><b>Ud. se encuentra en :<br>'+this.appService.mesa.nombre+'</b></div>');
              this.appService.rutadirecta = '';
              this.router.navigate(['/']);  
        }        
    });

  }

}
