import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separadormiles'
})
export class SeparadormilesPipe implements PipeTransform {

    transform(input:any):string {
      var x = ''+input;
      var pattern = /(-?\d+)(\d{3})/;
//      console.log("transformando "+x);
      while (pattern.test(x))
          x = x.replace(pattern, "$1.$2");
      return x;
    }

}
