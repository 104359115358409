import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MesadirectaComponent } from './pages/mesadirecta/mesadirecta.component';

export const routes: Routes = [
//    { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
//    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: 'qr/:datosmesa', component: MesadirectaComponent },
    { path: 'carro',  component: PagesComponent, children: [
        { path: ':token', loadChildren: () => import('./pages/carro/carro.module').then(m => m.CarroModule), data: { breadcrumb: 'Carro' } },
    ]},
    { path: '',  component: PagesComponent, children: [
            { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule)},
            { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule)},
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
            { path: '', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule) }
        ]
    },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabledBlocking', // for one load page, without reload
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }