import { Component, OnInit, Inject,Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-dialogo',
  templateUrl: './dialogo.component.html',
  styleUrls: ['./dialogo.component.scss']
})
export class DialogoComponent implements OnInit {

  celular:boolean;
  titulo:string;
  contenido:string;
  cerrarlbl:string;
  rechazarlbl:string;
  aceptarlbl:string;
  okbtn:boolean;
  nokbtn:boolean;
  closebtn:boolean;
  mensaje:string;
  constructor(public dialogRef: MatDialogRef<DialogoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.okbtn = false;
    this.nokbtn = false;
    this.closebtn = true;
    this.cerrarlbl = 'Cerrar';
    this.aceptarlbl = 'Aceptar';
    this.rechazarlbl = 'Rechazar';
    this.celular = false;
    if (data['titulo'])  this.titulo = data['titulo'];
    if (data['contenido']) this.mensaje = data['contenido'];
    if (data['okboton']) this.okbtn = true;
    if (data['nokboton']) this.nokbtn = true;
    if (data['closeboton']) this.closebtn = true;
    if (data['aceptarlbl']) this.aceptarlbl = data['aceptarlbl'];
    if (data['rechazarlbl']) this.rechazarlbl = data['rechazarlbl'];
    if (data['cerrarlbl']) this.cerrarlbl = data['cerrarlbl'];

//    console.log(this.cepas);
   }

  ngOnInit() {
    window.scrollTo(0,0);
  }

  close(valor=null)
  {
    var salida = { resultado:0};
    if (valor != null) salida = valor;   
    this.dialogRef.close(salida);
  }

  ok()
  {
    let resultado = {};
    this.close();
  }

  nok()
  {
    let resultado = { resultado: 0};
    this.close(resultado);
  }

   stopClickPropagate(event: any)
   {
    event.stopPropagation();
    event.preventDefault();
  }


}
